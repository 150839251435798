import React, { useRef } from "react";
import { Routes, Route } from "react-router";
import { routes } from "./config";
import PageNotFound from "../Components/PageNotFound";
import { SnackbarProvider } from "notistack";
import styled from "styled-components";
import Close from "../Components/Icons/Close";

const SnackbarProviderStyled = styled(SnackbarProvider)`
  &.SnackbarItem-contentRoot {
    background-color: #30475e;
  }
  &.SnackbarItem-variantError {
    background-color: #d32f2f;
  }
`;
const Routers = () => {
  const notistackRef = useRef();

  return (
    <SnackbarProviderStyled
      maxSnack={3}
      anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      ref={notistackRef}
      action={(key) => (
        <div
          onClick={() => notistackRef.current.closeSnackbar(key)}
          color="inherit"
          style={{ cursor: "pointer" }}
        >
          <Close color="#fff" />
        </div>
      )}
    >
      <Routes>
        {routes.map(({ routePath, Component }) => {
          return (
            <Route key={routePath} path={routePath} element={<Component />} />
          );
        })}

        {/* if route not matches any routePath from routes redirecting to page not found */}
        <Route path="/not-found" element={<PageNotFound />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
    </SnackbarProviderStyled>
  );
};

export default Routers;
