import React, { useState, useRef, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  ChattingPageContainer,
  Header,
  Content,
  Footer,
  ChatInputContainer,
  ChatInputField,
  ChatInputSendIcon,
  ChatInputSendIconContainer,
  StaticContentContainer,
  StatikContent,
} from "./styles";
import InputSendIcon from "../../Components/Icons/inputSendIcon";
import useNotification from "../../utils/useNotification";
import ChatResultCard from "./ChatResultCard_V1";
import { services } from "../../utils/serviceList";
import getModifiedString from "../../utils/getModifiedString";
import RefreshIcon from "../../Components/Icons/refreshIcon";
import RelatedQuestions from "./RelatedQuestions";
import { baseURL } from "../../utils/requestUrls";
import PauseIcon from "../../Components/Icons/pause.png";
import { parse } from "best-effort-json-parser";

const ChattingPage = () => {
  let { service } = useParams();
  const navigate = useNavigate();

  const [showErrorMessage] = useNotification();
  const [chatInputValue, setChatInputValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const contentDisplayRef = useRef(null);
  const initialScrollHeight = useRef(null);
  const textareaRef = useRef(null);
  const [isManuallyScrolled, setIsManuallyScrolled] = useState(false);

  const [chatHistory, setChatHistory] = useState([]);
  const [responseChatHistory, setResponseChatHistory] = useState([]);
  const [nextQuestions, setNextQuestions] = useState([]);
  const [backUpNextQuestions, setBackUpNextQuestions] = useState([]);
  const [chatAnswer, setChatAnswer] = useState("");
  const [preparingQuestions, setPreparingQuestions] = useState(false);

  const isStreaming = useRef(true);
  const abortController = new AbortController();

  const [gptOptions] = useState([
    {
      name: "Power",
      value: "gpt-4-0125-preview",
    },
    {
      name: "Normal",
      value: "gpt-3.5-turbo-1106",
    },
  ]);
  const [selectedGptOption, setSelectedGptOption] =
    useState("gpt-4-0125-preview");

  const dataSources = services?.["data_sources"] || [];
  const serviceLogo = services?.[service]?.["serviceLogo"] || null;
  const serviceChatLogo = services?.[service]?.["serviceChatLogo"] || null;
  const accentBGColor = services?.[service]?.["accentBGColor"] || "#FFF";
  const chatBGColor = services?.[service]?.["chatBGColor"] || "#F6FAFF";
  const welcomeText = `**Your Personal Assistant at Your Service**\n\nI'm excited to help you navigate the world of luxury and convenience. Whether it's booking exclusive events, planning travel itineraries, or sending gifts to your loved ones, I'm here to help make your life more comfortable and efficient.\n\nHow can I assist you today?`;

  const [modifiedString] = getModifiedString();

  useEffect(() => {
    if (!services["services"].includes(service)) {
      navigate("/not-found");
    }
  }, [service, navigate]);

  useEffect(() => {
    if (contentDisplayRef?.current) {
      let currentScrollHeight = contentDisplayRef?.current?.scrollHeight;
      initialScrollHeight.current = contentDisplayRef?.current?.scrollHeight;

      const intervalId = setInterval(() => {
        if (
          contentDisplayRef?.current &&
          currentScrollHeight !== contentDisplayRef?.current?.scrollHeight
        ) {
          contentDisplayRef.current.scrollTop =
            contentDisplayRef?.current?.scrollHeight;
          currentScrollHeight = contentDisplayRef?.current?.scrollHeight;
          initialScrollHeight.current = "increasedHeight";
        }
      }, 100); // Adjust the interval as needed

      return () => {
        // Clear the interval when the component is unmounted
        clearInterval(intervalId);
      };
    }
  }, [isManuallyScrolled]);

  const handleSendChatRequest = async (chatInputValue) => {
    try {
      setIsLoading(true);
      setIsManuallyScrolled(false);
      setNextQuestions([]); // clear the next questions for every other question

      // auto scroll to down when, if scroll is at the top and new question added
      const timeoutId = setTimeout(() => {
        if (contentDisplayRef?.current) {
          contentDisplayRef.current.scrollTop =
            contentDisplayRef?.current?.scrollHeight;
        }
        clearTimeout(timeoutId);
      }, 500);

      let chatHistories = [];
      for (let i = 0; i < chatHistory?.length; i++) {
        const { gptOption, ...rest } = chatHistory[i];
        chatHistories.push({ ...rest });
      }

      let payload = {
        org_id: "12231",
        user_id: "432",
        request_id: "21",
        llm_model: selectedGptOption,
        data_source: dataSources,
        question: chatInputValue,
        chat_history: chatHistories,
      };
      let chatHistoryArray = [...chatHistory] || [];
      chatHistoryArray.push({
        user: chatInputValue,
        contextualiseUser: chatInputValue,
      });

      setChatHistory([...chatHistoryArray]);
      setChatInputValue("");
      // setChatAnswer("");

      await fetch(`${baseURL}chat/stream`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
        signal: abortController.signal,
      })
        .then(async (response) => {
          const reader = response?.body
            ?.pipeThrough(new TextDecoderStream("utf-8"))
            .getReader();

          let string = "";
          let answer = "";

          while (true) {
            const { value, done } = await reader.read();

            if (value) {
              let jsonString = value;
              jsonString = jsonString.replace(/\n/g, `\\\\n`);
              const jsonArrayString = "[" + jsonString + "]";

              const parsedArray = parse(jsonArrayString);
              let str = string;
              parsedArray.forEach((item) => {
                str = str + item?.content || "";
              });
              string = str;
            }

            if (string?.includes(`{\n  "answer": "`)) {
              if (
                !string?.includes(
                  `",\n  "follow` || !string?.includes(`followup_questions`)
                )
              ) {
                answer = string?.replace(`{\n  "answer": "`, "");
              } else {
                answer = answer?.replace(`",\n  "`, ".");
              }
              setChatAnswer(answer);
            }

            if (string?.includes("followup_questions")) {
              setPreparingQuestions(true);
            }

            if (!isStreaming?.current) {
              abortController.abort();

              chatHistoryArray[chatHistoryArray?.length - 1]["assistant"] =
                answer || "";
              chatHistoryArray[chatHistoryArray?.length - 1]["gptOption"] =
                selectedGptOption === "gpt-3.5-turbo-1106" ? "" : "Power";
              setChatHistory([...chatHistoryArray]);
              setResponseChatHistory([...chatHistoryArray]);
              setNextQuestions([]);
              setBackUpNextQuestions([]);
              setChatAnswer("");
              setIsLoading(false);
              setPreparingQuestions(false);
              break;
            }

            if (done) {
              setIsLoading(false);
              const overallString = JSON.parse(string);

              chatHistoryArray[chatHistoryArray?.length - 1]["assistant"] =
                overallString?.answer || "";
              chatHistoryArray[chatHistoryArray?.length - 1]["gptOption"] =
                selectedGptOption === "gpt-3.5-turbo-1106" ? "" : "Power";

              setChatHistory([...chatHistoryArray]);
              setResponseChatHistory([...chatHistoryArray]);
              setNextQuestions([...(overallString?.followup_questions || [])]);
              setBackUpNextQuestions([
                ...(overallString?.followup_questions || []),
              ]);
              setChatAnswer("");
              setPreparingQuestions(false);
              break;
            }
          }
        })
        .catch((error) => {
          setChatHistory(JSON.parse(JSON.stringify([...responseChatHistory])));
          setNextQuestions([...backUpNextQuestions]);
          setPreparingQuestions(false);
          setIsLoading(false);
          setChatAnswer("");
          showErrorMessage(error?.message || "Something went wrong!");
        })
        .finally(() => {
          setIsLoading(false);
          isStreaming.current = true;
        });
    } catch (error) {
      setIsLoading(false);
      showErrorMessage(error?.message || "Something went wrong!");
    }
  };

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, []);

  return (
    <ChattingPageContainer>
      <Header accentBGColor={accentBGColor}>
        <img src={serviceLogo} alt="logo" />
        <div className="drop-refresh-container">
          <div>
            <select
              id="dropdown"
              value={selectedGptOption}
              onChange={(event) => setSelectedGptOption(event.target.value)}
              style={{ color: accentBGColor }}
            >
              {gptOptions?.map((item) => (
                <option value={item?.value}>{item?.name}</option>
              ))}
            </select>
          </div>
          <div
            style={{ cursor: "pointer" }}
            onClick={() => window.location.reload()}
          >
            <RefreshIcon />
          </div>
        </div>
      </Header>
      <Content
        ref={isManuallyScrolled ? null : contentDisplayRef} // disabled ref when manually scrolled
        className="scroll-bar"
        onWheel={() => {
          // if scrolling manually when content scrollHeight increased, then only hide auto scroll down along eith content
          if (initialScrollHeight?.current === "increasedHeight") {
            setIsManuallyScrolled(true);
          }
        }}
      >
        {!chatHistory?.length > 0 && (
          <StaticContentContainer>
            <StatikContent>
              <img alt="ic" src={serviceChatLogo} />
              <span
                dangerouslySetInnerHTML={{
                  __html: modifiedString(welcomeText),
                }}
              ></span>
            </StatikContent>
          </StaticContentContainer>
        )}
        {chatHistory?.map((chatItem, index) => {
          return (
            <>
              <ChatResultCard
                isUser
                chatMessage={chatItem?.user}
                contentDisplayRef={contentDisplayRef}
              />
              {chatItem?.assistant && (
                <ChatResultCard
                  chatMessage={chatItem?.assistant}
                  contentDisplayRef={contentDisplayRef}
                  // isManuallyScrolled={isManuallyScrolled}
                  chatBGColor={chatBGColor}
                  serviceChatLogo={serviceChatLogo}
                  gptOption={chatItem?.gptOption || ""}
                />
              )}
              {isLoading &&
                chatHistory?.length - 1 === index &&
                !chatItem?.assistant && (
                  <ChatResultCard
                    chatMessage={chatAnswer || "Typing"}
                    isGeneratingAnswer
                    chatBGColor={chatBGColor}
                    serviceChatLogo={serviceChatLogo}
                    gptOption={chatItem?.gptOption || ""}
                  />
                )}
              {((!isLoading &&
                nextQuestions?.length > 0 &&
                chatHistory?.length - 1 === index) ||
                (preparingQuestions && chatHistory?.length - 1 === index)) && (
                <RelatedQuestions
                  nextQuestions={nextQuestions}
                  handleSendChatRequest={handleSendChatRequest}
                  preparingQuestions={preparingQuestions}
                />
              )}
            </>
          );
        })}
      </Content>
      <Footer>
        <ChatInputContainer>
          <ChatInputField
            disabled={isLoading}
            value={chatInputValue}
            id="chat"
            name="chat"
            type="string"
            placeholder="Ask a question..."
            onChange={(e) => {
              setChatInputValue(e.target.value);
            }}
            ref={textareaRef}
            className="auto-growable-textarea scroll-bar"
            maxRows={4}
            onKeyDown={(e) => {
              if (!isLoading) {
                if (e.key === "Enter" && !e.shiftKey) {
                  if (e.target.value?.length > 2) {
                    setChatInputValue(e.target.value);
                    handleSendChatRequest(e.target.value);
                  } else {
                    showErrorMessage("Please enter at least 3 characters");
                  }
                }
              }
            }}
          />
          <ChatInputSendIconContainer
            onClick={() => {
              if (!isLoading) {
                if (chatInputValue?.length > 2) {
                  handleSendChatRequest(chatInputValue);
                } else {
                  showErrorMessage("Please enter at least 3 characters");
                }
              }
            }}
          >
            <ChatInputSendIcon>
              {isLoading ? (
                <img
                  alt="ic"
                  src={PauseIcon}
                  onClick={() => {
                    isStreaming.current = false;
                  }}
                />
              ) : (
                <InputSendIcon
                  color={chatInputValue?.length > 0 ? "#00505a" : "#F6FAFF"}
                  fill={chatInputValue?.length > 0 ? "#fff" : "#828282"}
                />
              )}
            </ChatInputSendIcon>
          </ChatInputSendIconContainer>
        </ChatInputContainer>
        <div className="powered-by">powered by Thought Labs</div>
      </Footer>
    </ChattingPageContainer>
  );
};
export default ChattingPage;
