import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Routers from "./routes";
import "./styles/fonts.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/*" element={<Routers />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
