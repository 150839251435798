import { useSnackbar } from "notistack";

const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showErrorMessage = (msg) => {
    enqueueSnackbar(msg, {
      variant: "error",
      autoHideDuration: 5000,
    });
  };

  const showSuccessMessage = (msg) => {
    enqueueSnackbar(msg, {
      variant: "success",
      autoHideDuration: 5000,
    });
  };
  return [showErrorMessage, showSuccessMessage];
};

export default useNotification;
