import React, { useRef, useEffect, useState } from "react";
import { ResultContent } from "./styles";
import UserAvatarIcon from "../../Components/Icons/userAvatarIcon.svg";
import getModifiedString from "../../utils/getModifiedString";

const ChatResultCard = ({
  index,
  isUser = false,
  chatMessage = "",
  isGeneratingAnswer,
  chatBGColor = "",
  serviceChatLogo = null,
  gptOption = "",
}) => {
  const [modifiedString] = getModifiedString();
  const someFunctionRef = useRef((string) => {
    return modifiedString(string);
  });

  let displayText = "";
  let i = 0;

  while (i < chatMessage.length) {
    // Check if current character is '\'
    if (chatMessage[i] === "\\" && chatMessage[i + 1] === "n") {
      displayText += `<br/>`;
      i += 2; // Move two characters ahead to skip '\n'
    } else {
      displayText += chatMessage[i];
      i++;
    }
  }

  const [dots, setDots] = useState(".");

  useEffect(() => {
    if (chatMessage === "Typing" && !isUser) {
      const interval = setInterval(() => {
        if (chatMessage === "Typing") {
          setDots((prevDots) => {
            if (prevDots?.length >= 3) {
              return ".";
            } else {
              return prevDots + ".";
            }
          });
        } else {
          clearInterval(interval);
        }
      }, 500);
      return () => clearInterval(interval);
    }
  }, [chatMessage, isUser]);

  return (
    <ResultContent key={index} isUser={isUser} chatBGColor={chatBGColor}>
      {isUser ? (
        <img alt="ic" src={UserAvatarIcon} />
      ) : (
        <div className="img-div">
          <img alt="ic" src={serviceChatLogo} />
        </div>
      )}
      <span
        dangerouslySetInnerHTML={{
          __html: isUser
            ? chatMessage
            : chatMessage === "Typing"
            ? chatMessage + dots
            : someFunctionRef?.current(displayText),
        }}
      />

      {!isGeneratingAnswer && gptOption && (
        <div className="power-text">Power</div>
      )}
    </ResultContent>
  );
};
export default ChatResultCard;
