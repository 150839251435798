import MonumentLogo from "../Components/Icons/ServicesLogoIcons/Monument_logo.svg";
import MonumentChatLogo from "../Components/Icons/ServicesLogoIcons/Monument_chat_logo.svg";

export const services = {
  services: ["monument"],
  data_sources: ["mmservices"],
  monument: {
    service: "monument",
    data_source: "mmservices",
    serviceLogo: MonumentLogo,
    serviceChatLogo: MonumentChatLogo,
    accentBGColor: "#00505a",
    chatBGColor: "#ccdcde",
  },
};
