import styled from "styled-components";
import TextareaAutosize from "react-textarea-autosize";

const maxWidth = "425px";

export const ChattingPageContainer = styled.div`
  height: 100%;
  //   width: 100%;
  // padding: 0% 2% 1% 2%;
  display: flex;
  flex-direction: column;
  font-family: Average-Sans;
  @media (max-width: ${maxWidth}) {
    // padding: 0% 4% 4% 4%;
  }
`;
export const Header = styled.div`
  height: 72px;
  //   border: 1px solid red;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${({ accentBGColor }) => accentBGColor};
  span {
    color: #000;
    font-size: 25px;
    font-weight: 400;
    @media (max-width: ${maxWidth}) {
      font-size: 21px;
    }
  }
  img {
    width: 180px;
    @media (max-width: ${maxWidth}) {
      width: 120px;
    }
  }
  .drop-refresh-container {
    width: max-content;
    height: max-content;
    display: flex;
    position: absolute;
    right: 22px;
    align-items: center;
    // cursor: pointer;
  }
  select {
    height: 28px;
    width: 100px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 16px;
  }
  option {
    cursor: pointer;
  }
  @media (max-width: ${maxWidth}) {
    height: 42px;
    .drop-refresh-container {
      right: 8px;
    }
    select {
      height: 22px;
      width: 65px;
      font-size: 10px;
      margin-right: 6px;
    }
    option {
      font-size: 10px;
    }
  }
`;
export const Content = styled.div`
  flex: 1;
  // height: calc(100vh - 155px);
  overflow: auto;
  padding: 10px 12px;
  @media (max-width: ${maxWidth}) {
    height: calc(100vh - 125px);
  }
`;
export const Footer = styled.div`
  margin-bottom: 10px;
  padding: 0px 12px;
  @media (max-width: ${maxWidth}) {
  }
  .powered-by {
    display: flex;
    align-items: center;
    justify-content: end;
    color: #3f3f3f;
    font-size: 14px;
    /* font-weight: 400; */
    margin: 4px 0px 0px;
    @media (max-width: ${maxWidth}) {
      font-size: 12px;
    }
  }
`;
export const LogoContent = styled.div`
  position: absolute;
  height: 100%;
  left: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  img {
    height: 52px;
    width: 51px;
    @media (max-width: ${maxWidth}) {
      height: 39px;
      width: 38px;
    }
  }
  span {
    color: #000;
    font-size: 22px;
    font-weight: 400;
    @media (max-width: ${maxWidth}) {
      display: none;
    }
  }
`;

export const ChatInputField = styled(TextareaAutosize)`
  flex: 1;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  // min-height: 64px;
  // max-height: 200px;
  border: none;
  border: 1px solid #e6e6e6;
  padding: 16px 60px 22px 14px;
  color: #000000;
  font-size: 22px;
  font-weight: 400;
  font-family: Average-Sans;
  overflow-x: hidden;
  resize: none;
  // height: 18px;
  // max-height: 120px;
  // resize: none;
  &:focus {
    outline: none; /* Remove the default focus outline */
    border: 1px solid #e6e6e6; /* Remove the border on focus */
  }
  @media (max-width: ${maxWidth}) {
    // height: 18px;
    // max-height: 120px;
    border-radius: 4px;
    border: 1px solid #e5e6eb;
    padding: 16px 44px 20px 8px;
    font-size: 14px;
  }
`;

export const ChatInputContainer = styled.div`
  width: 100%;
  position: relative;

  display: flex;
  .auto-growable-textarea {
    overflow-x: hidden;
    overflow-y: auto;
  }
`;
export const ChatInputSendIcon = styled.div`
  //   position: absolute;
  height: 44px;
  width: 45px;
  //   right: 10px;

  img {
    height: 36px;
    width: 36px;
    border-radius: 100px;
    &:hover {
      background: #dedede;
    }
  }
  @media (max-width: ${maxWidth}) {
    height: 29px;
    width: 30px;
    img {
      height: 24px;
      width: 24px;
    }
  }
`;
export const ChatInputSendIconContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 60px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  @media (max-width: ${maxWidth}) {
    width: 44px;
    right: 0px;
  }
`;
export const StaticContentContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${maxWidth}) {
  }
`;
export const StatikContent = styled.div`
  max-width: 75%;
  position: relative;
  bottom: 20px;
  border-radius: 5px;
  border: 1px solid #626262;
  padding: 14px;
  display: flex;
  gap: 8px;

  span {
    color: #626262;
    font-size: 22px;
    font-weight: 400;
    white-space: break-spaces;
  }
  img {
    width: 28px;
    height: 28px;
    @media (max-width: ${maxWidth}) {
      width: 20px;
      height: 20px;
    }
  }
  @media (max-width: ${maxWidth}) {
    font-size: 14px;
    max-width: 100%;
    bottom: 12px;
    gap: 7px;
    padding: 10px;
    span {
      font-size: 14px;
    }
  }
`;
export const ResultContent = styled.div`
  background: ${({ isUser, chatBGColor }) => (isUser ? "none" : chatBGColor)};
  min-height: ${({ isUser }) => (isUser ? "44px" : "60px")};
  padding: ${({ isUser }) =>
    isUser ? "30px 14px 14px 14px" : "14px 14px 20px 14px;"};
  gap: 10px;
  display: flex;
  border-radius: 6px;
  position: relative;
  span {
    color: #000;
    font-size: 22px;
    font-weight: 400;
    text-wrap: wrap;
    overflow: hidden;
    white-space: break-spaces;
    @media (max-width: ${maxWidth}) {
      font-size: 14px;
    }
  }
  .img-div {
    border: 1px solid #878787;
    padding: 4px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: max-content;
    width: max-content;
    @media (max-width: ${maxWidth}) {
      // width: 23px;
      // height: 23px;
    }
  }
  img {
    width: ${({ isUser }) => (isUser ? "30px" : "20px")};
    height: ${({ isUser }) => (isUser ? "30px" : "20px")};
    @media (max-width: ${maxWidth}) {
      width: ${({ isUser }) => (isUser ? "20px" : "16px")};
      height: ${({ isUser }) => (isUser ? "20px" : "16px")};
    }
  }
  .power-text {
    position: absolute;
    bottom: 0px;
    right: 10px;
    font-size: 18px;
  }
  @media (max-width: ${maxWidth}) {
    gap: 8px;
    min-height: ${({ isUser }) => (isUser ? "25px" : "30px")};
    padding: ${({ isUser }) =>
      isUser ? "14px 8px 8px 8px" : "10px 8px 16px 8px"};

    .power-text {
      font-size: 14px;
    }
  }
`;
export const RelatedQuestionsContainer = styled.div`
  padding: 10px 40px;
  gap: 16px;
  display: flex;
  flex-direction: column;
  @media (max-width: ${maxWidth}) {
    padding: 10px 20px;
    gap: 8px;
  }
`;
export const RelatedQuestionsTitle = styled.div`
  color: #000;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.8px;
  cursor: pointer;
  width: max-content;
  display: flex;
  align-items: center;
  img {
    height: 36px;
    width: 36px;
    margin-right: 6px;
  }
  @media (max-width: ${maxWidth}) {
    font-size: 14px;
    letter-spacing: 0.5px;
    img {
      height: 26px;
      width: 24px;
      margin-right: 4px;
    }
  }
`;
export const QuestionList = styled.div`
  width: max-content;
  max-width: 96%;
  padding: 6px 16px;
  color: blue;
  border: 2px solid blue;
  display: flex;
  align-items: center;
  font-size: 20px;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  @media (max-width: ${maxWidth}) {
    font-size: 12px;
    border: 1px solid blue;
    padding: 2px 10px;
    max-width: 94%;
  }
`;
