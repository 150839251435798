import React from "react";

export default function Close({ color = "#434343" }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 8.88906L13.8891 5L15 6.11094L11.1109 10L15 13.8891L13.8891 15L10 11.1109L6.11094 15L5 13.8891L8.88906 10L5 6.11094L6.11094 5L10 8.88906Z"
        fill={color}
      />
    </svg>
  );
}
