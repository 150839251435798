const getModifiedString = () => {
  // Regular expression to find links starting with https://
  const linkRegex = /(https:\/\/[^\s\])]+)[^\s\].,;:'"()-]+/g; // removing the if the special characters([^\s\)\.,;:'"-]+) present at the end of the link

  // Function to replace links with anchor tags
  const renderLinks = (text) =>
    text.replace(
      linkRegex,
      (match) =>
        `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`
    );

  // Function to replace bold text with <strong> tags
  const renderBold = (text) =>
    text.replace(boldRegex, (match, p1) => `<b>${p1}</b>`);

  // Regular expression to find bold text within double asterisks
  const boldRegex = /\*\*(.*?)\*\*/g;

  // Function to process the input string and render bold text and links
  const processInput = (input) => {
    let result = input;

    // Render bold text
    result = renderBold(result);

    // Render links
    result = renderLinks(result);

    return result;
  };

  return [processInput];
};

export default getModifiedString;
