import React from "react";

export default function InputSendIcon({ color = "#F6FAFF", fill = "#828282" }) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 46 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.459473" width="45.2791" height="44" rx="5" fill={color} />
      <path
        d="M32.6796 22.5613C32.893 22.4626 33.0725 22.311 33.1979 22.1235C33.3232 21.936 33.3896 21.72 33.3896 21.4997C33.3896 21.2793 33.3232 21.0633 33.1979 20.8758C33.0725 20.6883 32.893 20.5368 32.6796 20.438L14.6703 12.1255C14.447 12.0224 14.196 11.9815 13.9479 12.0078C13.6998 12.034 13.4654 12.1264 13.2733 12.2736C13.0811 12.4208 12.9395 12.6164 12.8657 12.8366C12.7919 13.0568 12.7892 13.2921 12.8577 13.5137L14.696 19.4512C14.7729 19.6994 14.9353 19.9176 15.1585 20.073C15.3818 20.2283 15.6539 20.3123 15.9335 20.3122L21.8136 20.3122C22.1547 20.3122 22.4819 20.4373 22.7232 20.66C22.9644 20.8827 23.0999 21.1847 23.0999 21.4997C23.0999 21.8146 22.9644 22.1166 22.7232 22.3393C22.4819 22.562 22.1547 22.6872 21.8136 22.6872L15.9335 22.6872C15.6539 22.687 15.3818 22.771 15.1585 22.9263C14.9353 23.0817 14.7729 23.2999 14.696 23.5481L12.859 29.4856C12.7903 29.7072 12.7929 29.9424 12.8665 30.1626C12.9401 30.3828 13.0816 30.5785 13.2736 30.7258C13.4656 30.8731 13.6999 30.9656 13.9479 30.9921C14.1959 31.0186 14.4469 30.9779 14.6703 30.875L32.6796 22.5625L32.6796 22.5613Z"
        fill={fill}
      />
    </svg>
  );
}
