import React, { useState, useEffect } from "react";
import {
  RelatedQuestionsContainer,
  RelatedQuestionsTitle,
  QuestionList,
} from "./styles";
import addListIcon from "../../Components/Icons/add-list.png";

const RelatedQuestions = ({
  nextQuestions,
  handleSendChatRequest,
  preparingQuestions,
}) => {
  const [showRelatedQuestions, setShowRelatedQuestions] = useState(false);

  const [dots, setDots] = useState(".");

  useEffect(() => {
    if (preparingQuestions) {
      const interval = setInterval(() => {
        if (preparingQuestions) {
          setDots((prevDots) => {
            if (prevDots?.length >= 3) {
              return ".";
            } else {
              return prevDots + ".";
            }
          });
        } else {
          clearInterval(interval);
        }
      }, 500);
      return () => clearInterval(interval);
    }
  }, [preparingQuestions]);

  return (
    <RelatedQuestionsContainer>
      <RelatedQuestionsTitle
        onClick={() => {
          if (!preparingQuestions) {
            setShowRelatedQuestions(!showRelatedQuestions);
          }
        }}
      >
        <img src={addListIcon} alt="ic" />
        {preparingQuestions
          ? `Preparing Related Questions${dots}`
          : "Related Questions"}
      </RelatedQuestionsTitle>
      {showRelatedQuestions && (
        <>
          {nextQuestions?.map((question, index) => (
            <QuestionList
              key={index}
              onClick={() => {
                const timeoutId = setTimeout(() => {
                  setShowRelatedQuestions(false);
                  handleSendChatRequest(question);
                  clearTimeout(timeoutId);
                }, 800);
              }}
            >{`${question}`}</QuestionList>
          ))}
        </>
      )}
    </RelatedQuestionsContainer>
  );
};
export default RelatedQuestions;
